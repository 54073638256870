/* v8 ignore next 100 */

import { useResolvedPath, useRevalidator } from "@remix-run/react";
import { useEffect } from "react";
import { useTypedLoaderData } from "remix-typedjson";
import { useEventSource } from "remix-utils/sse/react";

export function useLiveLoader<T>() {
  let path = useResolvedPath("./subscribenotificationstream");
  let data = useEventSource(path.pathname, {
    event: "notification",
  });
  let { revalidate } = useRevalidator();

  useEffect(() => {
    if (data) {
      revalidate();
    }
  }, [data, revalidate]);

  return useTypedLoaderData<T>();
}
