import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { useEffect, type PropsWithChildren } from "react";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import "~/tailwind.css";
import { findInternalEmployeeByLoginID } from "./DatabaseInteractionClasses/InternalEmployeeDIC.server";
import { Connect8Toaster } from "./components/Inputgroups/Sonner";
import Footer from "./components/footer";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { useToast } from "./components/toaster";
import { loginAuthenticator } from "./services/auth.server";
import { csrf } from "./services/csrf.server";
import type {
  language,
  login_notification,
  user_shop_filter,
} from "./services/db.server";
import { LoginType } from "./services/db.server";
import { makeTimings } from "./services/timing.server";
import { getToast } from "./services/toast.server";

import { useChangeLanguage } from "remix-i18next/react";
import { typedjson } from "remix-typedjson";
import type { HoneypotInputProps } from "remix-utils/honeypot/server";
import { toast } from "sonner";
import {
  getAllNotificationsByLoginID,
  setIsReadByLoginNotificationID,
} from "./DatabaseInteractionClasses/LoginNotificationDIC.server";
import ErrorPage from "./components/error/ErrorPage";
import { SUBMIT_INTENT } from "./constants/routeConstants";
import { useTheme } from "./routes/resources+/theme-switch";
import {
  getCachedCompanyForLoginID,
  getCachedLanguageByLanguageID,
  getCachedLoginByLoginID,
  getCachedQuickfiltersForLoginID,
  getCachedUserByLoginID,
} from "./services/cache.server";
import { honeypot } from "./services/honeypot.server";
import i18nServer, { localeCookie } from "./services/i18n.server";
import type { LoginExtended } from "./types/CombinedTypes";
import { ClientHintCheck, getHints } from "./utils/client-hints";
import { getEnv } from "./utils/env.server";
import { combineHeaders, getDomainUrl } from "./utils/misc";
import { useNonce } from "./utils/nonce-provider";
import type { Theme } from "./utils/theme.server";
import { getTheme } from "./utils/theme.server";
import { isValidGUID } from "./utils/tools";
import { useLiveLoader } from "./utils/useliveloader";

function Document({
  children,
  title = "Connect8",
  nonce,
  env = {},
  theme = "dark",
}: PropsWithChildren<{
  title?: string;
  nonce: string;
  env?: Record<string, string>;
  theme?: Theme;
}>) {
  let loaderData = useRouteLoaderData<typeof loader>("root");

  return (
    <html className={`h-full ${theme}`} lang={loaderData?.locale ?? "en"}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <meta name="color-scheme" content="light" /> */}
        <title>{title}</title>
        <Links />
      </head>
      <body className="h-full bg-bg-dark">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

export const action = async ({ request }: ActionFunctionArgs) => {
  let formdata = await request.formData();
  let loginNotificationID = formdata.get("notificationid");
  let intent = formdata.get(SUBMIT_INTENT);
  let notification: login_notification | null = null;
  switch (intent) {
    case "setread":
      if (loginNotificationID && isValidGUID(loginNotificationID as string)) {
        notification = await setIsReadByLoginNotificationID(
          loginNotificationID as string
        );
      }
      if (notification && notification.link) {
        return redirect(notification.link);
      } else {
        return redirect("../notifications");
      }

    case "default":
      return null;
  }
  return null;
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let timings = makeTimings("root loader");
  let [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  let authenticatedIDs = await loginAuthenticator.isAuthenticated(request);

  let login: LoginExtended | null = null;
  let honeyProps: HoneypotInputProps | null = null;
  let loginName = "";
  let notifications: login_notification[] = [];
  let quickfilters: user_shop_filter[] = [];
  let language: language | null = null;
  let profilePicture: string | null = null;
  if (authenticatedIDs) {
    login = await getCachedLoginByLoginID(authenticatedIDs.loginID);
    language = await getCachedLanguageByLanguageID(login.language_id);
    profilePicture = login.profilePicture;

    if (login) {
      if (login.LoginType === LoginType.Company) {
        let company = await getCachedCompanyForLoginID(
          authenticatedIDs.loginID
        );
        if (company) {
          loginName = company.CompanyName;
        }
      } else if (login.LoginType === LoginType.User) {
        let user = await getCachedUserByLoginID(authenticatedIDs.loginID);
        if (user) {
          loginName = user.ForeName + " " + user.LastName;
        }
      } else if (login.LoginType === LoginType.InternalEmployee) {
        let employee = await findInternalEmployeeByLoginID(
          authenticatedIDs.loginID
        );
        if (employee) {
          loginName = employee.ForeName + " " + employee.LastName;
        }
      }

      notifications = await getAllNotificationsByLoginID(login.id);
      quickfilters = await getCachedQuickfiltersForLoginID(login.id);
    }
  }
  honeyProps = honeypot.getInputProps();
  let { toast, headers: toastHeaders } = await getToast(request);

  let locale = language
    ? language.iso_code
    : await i18nServer.getLocale(request);

  return typedjson(
    {
      login: login,
      profilePicture,
      IsUserAlreadyLoggedIn: Boolean(login),
      toast,
      honeyProps,
      csrfToken,
      loginName,
      notifications: notifications,
      MessageCount: notifications.length,
      quickfilters: quickfilters,
      locale,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { "set-cookie": csrfCookieHeader } : null,
        { "Set-Cookie": await localeCookie.serialize(locale) }
      ),
    }
  );
};

function App() {
  let data = useLiveLoader<typeof loader>();
  //const revalidator = useRevalidator();
  useToast(data?.toast);
  //useEffect(() => {
  //revalidator.revalidate();
  //}, [data?.login]);
  let nonce = useNonce();

  useEffect(() => {
    let tmpCreatedAt = data?.notifications[0]?.created_at?.valueOf();
    if (tmpCreatedAt) {
      let createdAt = new Date(tmpCreatedAt).valueOf();
      let datenow = new Date().valueOf();
      if (createdAt && datenow - createdAt < 1000 * 60) {
        toast.success(data?.notifications[0]?.message);
      }
    }
  }, [data.login?.last_notification_id, data?.notifications]);

  useChangeLanguage(data.locale);

  const theme = useTheme();

  return (
    <HoneypotProvider {...data?.honeyProps}>
      <Document theme={theme} nonce={nonce} env={data.ENV}>
        <div>
          {data && data.IsUserAlreadyLoggedIn && data.login ? (
            <>
              <Sidebar
                login={data.login}
                loginName={data.loginName}
                defaultFilters={data.quickfilters}
                theme={data.requestInfo.userPrefs.theme}
              />
              {/* <div className="flex-col"> */}
              <div className="bg-bg-light dark:bg-bg-dark">
                <div className="mx-auto max-w-7xl xl:ml-72">
                  <div className="bg-bg-light dark:bg-bg-dark py-10">
                    <div className="px-4 sm:px-6 lg:px-8">
                      <Header
                        notificationNumber={data.MessageCount as number}
                        login={data.login as any}
                        loginName={data.loginName}
                        notifications={data.notifications}
                        theme={data.requestInfo.userPrefs.theme}
                      />

                      <Outlet />
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </>
          ) : (
            <div>
              <Outlet />
            </div>
          )}
          {/* <div className="container flex justify-between pb-5">
            <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
          </div> */}
        </div>
        <Connect8Toaster closeButton position="top-center" theme={theme} />
      </Document>
    </HoneypotProvider>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  let error = useRouteError();
  let nonce = useNonce();

  if (isRouteErrorResponse(error)) {
    return (
      <Document title={`${error.status} ${error.statusText}`} nonce={nonce}>
        <ErrorPage errorResponse={error} />
      </Document>
    );
  }

  let errorMessage =
    error instanceof Error ? error.message : "error:unknownError";
  captureRemixErrorBoundaryError(error);
  return (
    <Document title="Uh-oh!" nonce={nonce}>
      <ErrorPage text={errorMessage} />
    </Document>
  );
}
