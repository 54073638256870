import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightIcon,
  ArrowRightStartOnRectangleIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  CurrencyEuroIcon,
  FolderIcon,
  GlobeAltIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  QueueListIcon,
  ServerIcon,
  ShoppingCartIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import type { user_shop_filter } from "@prisma/client";
import { Link, useLocation } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { CompanyAddressType, LoginType } from "~/constants/prismaEnums";
import {
  ROUTE_LOGISTIC,
  ROUTE_LOGISTICOVERVIEW_DONE,
  ROUTE_LOGISTICOVERVIEW_MYOFFERS,
  ROUTE_LOGISTICOVERVIEW_MYREQUESTS,
  ROUTE_LOGISTICOVERVIEW_PAST,
  ROUTE_TRADEOVERVIEW_MYOFFERS,
  ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY,
  ROUTE_TRADEOVERVIEW_MYREQUESTS,
  ROUTE_TRADEPRODUCT_CREATE,
  ROUTE_TRADEPRODUCTSHOP,
} from "~/constants/routeConstants";
import type { LoginExtended } from "~/types/CombinedTypes";
import { classNames } from "~/utils/misc";
import type { Theme } from "~/utils/theme.server";
import { Logo } from "./Logo";

interface Navigation {
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  children?: Navigation[] | undefined;
}

const userNavigation: Navigation[] = [
  {
    name: "newProductRequest",
    href: ROUTE_TRADEPRODUCT_CREATE,
    icon: PlusCircleIcon,
    children: undefined,
  },
  {
    name: "connect8trading",
    href: "",
    icon: ShoppingCartIcon,
    children: [
      {
        name: "openProductRequests",
        icon: MagnifyingGlassIcon,
        href: ROUTE_TRADEPRODUCTSHOP,
      },
      {
        name: "tradeMyRequests",
        icon: QueueListIcon,
        href: ROUTE_TRADEOVERVIEW_MYREQUESTS,
      },
      {
        name: "tradeMyOffers",
        icon: QueueListIcon,
        href: ROUTE_TRADEOVERVIEW_MYOFFERS,
      },
      {
        name: "tradeDelivery",
        icon: TruckIcon,
        href: ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY,
      },
    ],
  },
  {
    name: "connect8Logistic",
    href: "/connect8logistic",
    icon: GlobeAltIcon,
    children: [
      {
        name: "openLogisticRequests",
        icon: MagnifyingGlassIcon,
        href: ROUTE_LOGISTIC,
      },
      {
        name: "myLogisticRequests",
        icon: QueueListIcon,
        href: `${ROUTE_LOGISTICOVERVIEW_MYREQUESTS}`,
      },
      {
        name: "myLogisticOffers",
        icon: QueueListIcon,
        href: `${ROUTE_LOGISTICOVERVIEW_MYOFFERS}`,
      },
      {
        name: "doneLogistics",
        icon: CheckCircleIcon,
        href: `${ROUTE_LOGISTICOVERVIEW_DONE}`,
      },
      {
        name: "pastLogistics",
        icon: ArchiveBoxIcon,
        href: `${ROUTE_LOGISTICOVERVIEW_PAST}`,
      },
    ],
  },
  {
    name: "faq",
    href: "/generalfaq",
    icon: QuestionMarkCircleIcon,
    children: undefined,
  },
  {
    name: "addressManagement",
    href: "",
    icon: HomeIcon,
    children: [
      {
        name: "deliveryAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Delivery}`,
      },
      {
        name: "billingAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Billing}`,
      },
      {
        name: "loadingAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Loading}`,
      },
    ],
  },
  {
    name: "management",
    href: "",
    icon: CurrencyEuroIcon,
    children: [
      { name: "salesdashboard", icon: ArrowRightIcon, href: "salesdashboard" },
      {
        name: "invoicesoverview",
        icon: ArrowRightIcon,
        href: "invoiceoverview/Actual",
      },
    ],
  },
  // {
  //   name: "Pay Test",
  //   href: "/pay",
  //   icon: ArrowRightStartOnRectangleIcon,
  // },
  // {
  //   name: "Notification Test",
  //   href: "/notifications",
  //   icon: ArrowRightStartOnRectangleIcon,
  // },
  {
    name: "logout",
    href: "/logout",
    icon: ArrowRightStartOnRectangleIcon,
    children: undefined,
  },
];
const employeeNavigation: Navigation[] = [
  {
    name: "tasks",
    href: "/internal/tasklist",
    icon: FolderIcon,
    children: undefined,
  },
  {
    name: "logout",
    href: "/logout",
    icon: ArrowRightStartOnRectangleIcon,
    children: undefined,
  },
];
const companyNavigation: Navigation[] = [
  {
    name: "registerUser",
    href: "/registeruser",
    icon: ServerIcon,
    children: undefined,
  },
  {
    name: "addressManagement",
    href: "",
    icon: HomeIcon,
    children: [
      {
        name: "deliveryAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Delivery}`,
      },
      {
        name: "billingAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Billing}`,
      },
      {
        name: "loadingAddresses",
        icon: ArrowRightIcon,
        href: `addressmanagement/${CompanyAddressType.Loading}`,
      },
    ],
  },
  {
    name: "Statistiken/Abrechnung",
    href: "",
    icon: HomeIcon,
    children: [
      { name: "salesdashboard", icon: ArrowRightIcon, href: "salesdashboard" },
      {
        name: "invoicesoverview",
        icon: ArrowRightIcon,
        href: "invoiceoverview/Actual",
      },
    ],
  },
  {
    name: "logout",
    href: "/logout",
    icon: ArrowRightStartOnRectangleIcon,
    children: undefined,
  },
];

interface SidebarProps {
  login: LoginExtended;
  loginName: string;
  defaultFilters: user_shop_filter[] | undefined;
  theme: Theme | null;
}

export default function Sidebar({
  login,
  loginName,
  defaultFilters,
  theme,
}: SidebarProps) {
  let location = useLocation();

  let navigation = userNavigation;
  if (login?.LoginType) {
    switch (login.LoginType) {
      case LoginType.Company:
        navigation = companyNavigation;
        break;
      case LoginType.InternalEmployee:
        navigation = employeeNavigation;
        break;
    }
  }

  let { t } = useTranslation();

  return (
    <>
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
          <div className="flex pt-16 h-16 shrink-0 items-center justify-center justify-items-center">
            <Logo theme={theme} />
          </div>
          <nav className="flex flex-1 flex-col mt-11">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <Link
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? "text-button-light dark:text-button-dark"
                              : "text-gray-light dark:text-gray-dark hover:text-button-light hover:dark:text-button-dark",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              "h-6 w-6 shrink-0",
                              location.pathname === item.href
                                ? "text-button-light dark:text-button-dark"
                                : ""
                            )}
                            aria-hidden="true"
                          />
                          {t(item.name)}
                        </Link>
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <DisclosureButton
                                className={classNames(
                                  location.pathname === item.href ||
                                    item.children?.some(
                                      (c) => c.href === location.pathname
                                    )
                                    ? "text-button-light dark:text-button-dark"
                                    : "text-gray-light dark:text-gray-dark hover:text-button-light hover:dark:text-button-dark",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    "h-6 w-6 shrink-0",
                                    location.pathname === item.href ||
                                      item.children?.some(
                                        (c) => c.href === location.pathname
                                      )
                                      ? "text-button-light dark:text-button-dark"
                                      : ""
                                  )}
                                  aria-hidden="true"
                                />
                                {t(item.name)}
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-400",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </DisclosureButton>
                              <DisclosurePanel as="ul" className="mt-1 px-2">
                                {item.children &&
                                  item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          location.pathname === subItem.href
                                            ? "text-link-light dark:text-link-dark"
                                            : "text-gray-light dark:text-gray-dark hover:text-link-light hover:dark:text-link-dark",
                                          "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        <subItem.icon className="h-5 w-5 shrink-0" />
                                        {t(subItem.name)}
                                      </Link>
                                    </li>
                                  ))}
                              </DisclosurePanel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-light dark:text-gray-dark">
                  {t("yourQuickfilters")}
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {defaultFilters?.map((defaultFilter) => (
                    <li key={defaultFilter.id}>
                      <Link
                        to={`.${ROUTE_TRADEPRODUCTSHOP}?${defaultFilter.search_param_string}`}
                        className={classNames(
                          defaultFilter.filtertitle
                            ? "bg-gray-800 text-black dark:text-white"
                            : "text-gray-light dark:text-gray-dark hover:text-black hover:dark:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-black dark:text-white">
                          {"F"}
                        </span>
                        <span className="truncate">
                          {defaultFilter.filtertitle}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <a
                  href="/settings"
                  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-black dark:text-white hover:bg-gray-800"
                >
                  {login.profilePicture && (
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src={login.profilePicture}
                      alt=""
                    />
                  )}
                  <span className="sr-only">{t("yourProfile")}</span>
                  <span aria-hidden="true">{loginName}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
