import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast as showToast } from "sonner";
import type { Toast } from "~/services/toast.server";

export function useToast(toast?: Toast | null) {
  let { t } = useTranslation();
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        showToast[toast.type](t(toast.title ?? ""), {
          id: toast.id,
          description: t(toast.description),
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);
}
